<template lang="pug">
  .row
    .col
      h5 Сброс пароля

      form.form(@submit.prevent="doSendResetPasswordInstructions")
        .form-group
          label(for="login") E-mail
          input.form-control(type="text" id="email" placeholder="e-mail" v-model="email" required)

        button.btn.btn-primary(type="submit") Сбросить пароль
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      email: ''
    }
  },
  methods: {
    ...mapActions({
      sendResetPasswordInstructions: 'sendResetPasswordInstructions'
    }),
    doSendResetPasswordInstructions () {
      this.sendResetPasswordInstructions({ email: this.email }).then(() => {
        this.$notify({
          title: 'Сброс пароля',
          type: 'success',
          text: 'Запрос на сброс пароля выполнен, на указанную почту придет письмо с инструкцией по сбросу пароля'
        })
      })
    }
  }
}
</script>
