<template lang="pug">
  .row
    .col
      h5 Сброс пароля

      form.form(@submit.prevent="doReset")
        .form-group
          label(for="password") Пароль
          input.form-control(type="password" id="email" placeholder="Пароль" v-model="password" required)

        .form-group
          label(for="password_confirmation") Повторите пароль
          input.form-control(type="password" id="password_confirmation" placeholder="Пароль" v-model="password_confirmation" required)

        button.btn.btn-primary(type="submit") Сохранить
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      password: '',
      password_confirmation: ''
    }
  },
  methods: {
    ...mapActions({
      resetPassword: 'resetPassword'
    }),
    doReset () {
      const token = this.$route.query.reset_token
      const params = {
        password: this.password,
        password_confirmation: this.password_confirmation,
        token: token
      }

      if (this.password !== this.password_confirmation) {
        alert('Пароли не совпадают')
        return false
      }

      if (this.password.length < 8) {
        alert('Пароль должен быть 8 символов или больше')
        return false
      }

      this.resetPassword(params).then(() => {
        this.$notify({
          title: 'Сброс пароля',
          type: 'success',
          text: 'Сброс пароля выполнен успешно. Сейчас тебя переадресует на страницу входа'
        })

        window.location.href = '/users/sign_in'
      }).catch(() => {
        this.$notify({
          title: 'Сброс пароля',
          type: 'error',
          text: 'Сброся пароля не удался. Попробуй еще раз'
        })
      })
    }
  }
}
</script>
